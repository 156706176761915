import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>
        © 2024 NothingStore. All Rights Reserved. | 
        <FooterLink href="/privacy" aria-label="Privacy Policy">Privacy Policy</FooterLink> | 
        <FooterLink href="/termsOfService" aria-label="Terms of Service">Terms of Service</FooterLink>
      </FooterText>
    </FooterContainer>
  );
};

// Styled Components

const FooterContainer = styled.footer`
  background: #F5F5F5; /* Light crème background */
  padding: 20px 0;
`;

const FooterText = styled.p`
  color: #333333; /* Dark charcoal text color */
  text-align: center;
  font-size: 14px;
`;

const FooterLink = styled.a`
  color: #9A9A5F; /* Muted olive for links */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default Footer;
