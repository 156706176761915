import React from 'react';
import styled from 'styled-components';

const Description = () => {
  return (
    <Container id="desc">
      <Section>
        <Title>Why Buy "Nothing"?</Title>
        <Text>
          Embrace the unexpected with "nothing"—a unique gift that's all about fun and minimalism. Here's why it's the perfect choice:
        </Text>
        <Grid>
          <Card>
            <Icon role="img" aria-label="Gift box emoji">🎁</Icon>
            <Heading>Gag Gift</Heading>
            <p>Surprise someone with a gift that’s truly one-of-a-kind.</p>
          </Card>
          <Card>
            <Icon role="img" aria-label="Recycling symbol emoji">♻️</Icon>
            <Heading>Eco-Friendly</Heading>
            <p>No waste, just pure fun. A gift that’s gentle on the planet.</p>
          </Card>
          <Card>
            <Icon role="img" aria-label="Envelope emoji">✉️</Icon>
            <Heading>Customizable</Heading>
            <p>Personalize the certificate with names and messages for a personal touch.</p>
          </Card>
        </Grid>
      </Section>
      <Section>
        <Title>Practical Uses</Title>
        <Text>
          Beyond the fun, "nothing" has practical benefits:
        </Text>
        <Grid>
        <Card>
            <Icon role="img" aria-label="Credit card emoji">💙</Icon>
            <Heading>Share a Laugh</Heading>
            <p>Give the gift of laughter and create unforgettable moments with your friends, family, or colleagues.</p>
          </Card>
          <Card>
            <Icon role="img" aria-label="Credit card emoji">💳</Icon>
            <Heading>Card Activity</Heading>
            <p>Keep your credit card active for rewards and bonuses.</p>
          </Card>
          <Card>
            <Icon role="img" aria-label="Bar chart emoji">📊</Icon>
            <Heading>Payment Records</Heading>
            <p>Maintain regular spending records without unnecessary purchases.</p>
          </Card>
        </Grid>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  padding: 40px;
  background: #FFFFFF;
  color: #333333;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 20px;
`;

const Grid = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const Card = styled.article`
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 200px;
`;

const Icon = styled.div`
  font-size: 36px;
  margin-bottom: 10px;
`;

const Heading = styled.h3`
  font-size: 22px;
  margin-bottom: 10px;
`;

export default Description;
