import React from 'react';
import styled from 'styled-components';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Title>Privacy Policy</Title>
      <Section>
        <Subtitle>1. Introduction</Subtitle>
        <Text>
          Nothing Store is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website and make a purchase. Nothing Store is operated by Aphex Lab s.r.o., a company registered in the Czech Republic.
        </Text>
      </Section>
      <Section>
        <Subtitle>2. Information We Collect</Subtitle>
        <Text>
          <b>Email Address:</b> We collect your email address when you make a purchase. This information is necessary to send you the PDF certificate of "nothing" that you have purchased.
        </Text>
      </Section>
      <Section>
        <Subtitle>3. How We Use Your Information</Subtitle>
        <Text>
          <b>Certificate Delivery:</b> Your email address is used solely for the purpose of sending you the PDF certificate of "nothing" after your purchase.
          <br /><br />
          <b>Subscription Emails:</b> If you subscribe to "nothing," your email address will be used to send you a new certificate every month.
        </Text>
      </Section>
      <Section>
        <Subtitle>4. Information Sharing</Subtitle>
        <Text>
          <b>With Stripe:</b> Your email address and payment information are securely processed by Stripe, our payment processor. We do not store or have access to your credit card information.
          <br /><br />
          <b>No Third-Party Sharing:</b> We do not share, sell, or rent your personal information to any third parties for marketing or other purposes.
        </Text>
      </Section>
      <Section>
        <Subtitle>5. Data Security</Subtitle>
        <Text>
          We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
        </Text>
      </Section>
      <Section>
        <Subtitle>6. Data Retention</Subtitle>
        <Text>
          We retain your email address for as long as necessary to fulfill your purchase or subscription. If you wish to have your information deleted, please contact us at aphexlab (at) gmail.com.
        </Text>
      </Section>
      <Section>
        <Subtitle>7. Your Rights</Subtitle>
        <Text>
          <b>Access:</b> You have the right to request a copy of the personal information we hold about you.
          <br /><br />
          <b>Correction:</b> You may request that we correct or update any information that is inaccurate or incomplete.
          <br /><br />
          <b>Deletion:</b> You may request that we delete your personal information at any time by contacting us at aphexlab (at) gmail.com.
        </Text>
      </Section>
      <Section>
        <Subtitle>8. Cookies</Subtitle>
        <Text>
          We do not use cookies or track your activity on our website beyond what is necessary to complete your purchase.
        </Text>
      </Section>
      <Section>
        <Subtitle>9. Changes to this Privacy Policy</Subtitle>
        <Text>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review this policy periodically.
        </Text>
      </Section>
      <Section>
        <Subtitle>10. Contact Us</Subtitle>
        <Text>
          If you have any questions or concerns about this Privacy Policy, please contact us at aphexlab (at) gmail.com.
        </Text>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  padding: 40px;
  background: #F5F5F5;
  color: #333333;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 30px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.6;
`;

export default PrivacyPolicy;
