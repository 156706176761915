import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <FAQSection id="faq">
      <Title>Frequently Asked Questions</Title>
      <FAQContainer>
        {faqs.map((faq, index) => (
          <FAQItem key={index} itemScope itemType="https://schema.org/Question">
            <Question onClick={() => toggleFAQ(index)} itemProp="name">
              {faq.question}
              {activeIndex === index ? <FaChevronUp aria-label="Collapse answer" /> : <FaChevronDown aria-label="Expand answer" />}
            </Question>
            {activeIndex === index && (
              <Answer
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                transition={{ duration: 0.3 }}
                itemScope
                itemType="https://schema.org/Answer"
              >
                <p itemProp="text">{faq.answer}</p>
              </Answer>
            )}
          </FAQItem>
        ))}
      </FAQContainer>
    </FAQSection>
  );
};

// Styled Components

const FAQSection = styled.section`
  padding: 60px 20px;
  background: #FFFFFF;
  color: #333333;
`;

const Title = styled.h2`
  font-size: 32px;
  text-align: center;
  margin-bottom: 40px;
`;

const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const FAQItem = styled.article`
  margin-bottom: 20px;
  background: #F5F5F5;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
`;

const Question = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: #333333;
`;

const Answer = styled(motion.div)`
  padding: 15px 0;
  font-size: 16px;
  color: #666666;
`;

// FAQ Data

const faqs = [
  {
    question: "What do I actually get when I buy 'nothing'?",
    answer: "Absolutely nothing! But you'll feel great knowing you own a piece of nothing. Plus, you get a fancy certificate to prove it.",
  },
  {
    question: "Why should I buy 'nothing'?",
    answer: "Why not? It's a unique experience that costs little and provides a lot of laughs. Plus, it's a great conversation starter. It’s also practical! If you have a credit or debit card that requires a certain number of transactions each month, buying 'nothing' is an easy way to meet that requirement without purchasing things you don't need.",
  },
  {
    question: "Can I subscribe to 'nothing'?",
    answer: "Yes! For just $4.99 a month, you can enjoy the continuous satisfaction of owning nothing. A new certificate every month!",
  },
  {
    question: "Is there a refund policy for 'nothing'?",
    answer: "Once you've bought nothing, it's yours forever. But don't worry, there's nothing to regret! No but seriously, if you have any issues with your order or want a refund, please reach us at aphexlab (at) gmail.com and we will be able to help you.",
  },
  {
    question: "Can I gift 'nothing' to someone?",
    answer: "Yes! Nothing makes for the perfect gift when you don't know what to give. Simply put in their email address once you click on the buy link. The recipient will receive a certificate of nothing.",
  },
];

export default FAQ;
