import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import sampleImage from '../public/SampleNothingCert.png';
// Changed from react-scroll to traditional anchor for SEO improvement
// import { Link } from 'react-scroll';

const HeroSection = () => {
  return (
    <Header>
      <Hero>
        <Content>
          <TextSection>
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              Welcome to <Highlight>NothingStore</Highlight>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              Where buying nothing means everything.
            </motion.p>
            <StyledAnchor href="#desc">
              <CTAButton
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                aria-label="Explore Nothing"
              >
                Explore Nothing
              </CTAButton>
            </StyledAnchor>
          </TextSection>
          <ImageSection>
            <HeroImage src={sampleImage} alt="A symbolic image representing NothingStore" />
          </ImageSection>
        </Content>
      </Hero>
    </Header>
  );
};

const Header = styled.header`
  width: 100%;
  background-color: #F5F5F5;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
`;

const Hero = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #F5F5F5; /* Light crème background */
  color: #333; /* Darker text for contrast */
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 30px;
`;

const TextSection = styled.div`
  flex: 1;
  padding: 20px;
  max-width: 50%;
`;

const Highlight = styled.span`
  color: #9A9A5F; /* Muted olive for emphasis */
`;

const CTAButton = styled(motion.button)`
  margin-top: 30px;
  padding: 15px 30px;
  font-size: 18px;
  background: #9A9A5F; /* Muted olive CTA color */
  color: #FFFFFF;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
`;

const ImageSection = styled.div`
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: center;
`;

const HeroImage = styled.img`
  width: 100%;              /* Ensures the image takes up the full width of its container */
  max-width: 400px;         /* Restricts the maximum width to 400px */
  height: auto;             /* Maintains the aspect ratio of the image */
  object-fit: contain;      /* Scales the image down if necessary to fit within the max-width */
  margin: 0 auto;           /* Centers the image horizontally if the container is wider */
  display: block;           /* Prevents the image from having any unwanted padding/margin issues */
  loading: lazy;            /* Lazy load the image for better performance */
`;

export default HeroSection;
