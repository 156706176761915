import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { FaRegSmileWink, FaBars } from 'react-icons/fa';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavBar>
      <LeftSection>
        <Logo>
          <FaRegSmileWink aria-hidden="true" /> 
          <span>The Nothing Store</span>
        </Logo>
      </LeftSection>
      <CenterSection>
        <ProductHuntBadge
          href="https://www.producthunt.com/posts/the-nothing-store?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-the&#0045;nothing&#0045;store"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Check out The Nothing Store on Product Hunt"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=482178&theme=dark"
            alt="The Nothing Store - Buy and Subscribe to Nothing | Product Hunt"
            width="250"
            height="54"
            loading="lazy"  // Lazy loading for better performance
          />
        </ProductHuntBadge>
      </CenterSection>
      <RightSection>
        <Hamburger onClick={() => setIsOpen(!isOpen)} aria-label="Toggle navigation menu">
          <FaBars aria-hidden="true" />
        </Hamburger>
        <NavLinks isOpen={isOpen} aria-label="Primary navigation">
          <StyledLink to="desc" smooth duration={500} aria-label="Why buy nothing?">
            Why?
          </StyledLink>
          <StyledLink to="buy" smooth duration={500} aria-label="Buy Nothing">
            Buy Nothing
          </StyledLink>
          <StyledLink to="faq" smooth duration={500} aria-label="Frequently Asked Questions">
            FAQ
          </StyledLink>
          <StyledLink to="testimonials" smooth duration={500} aria-label="Customer Testimonials">
            Testimonials
          </StyledLink>
        </NavLinks>
      </RightSection>
    </NavBar>
  );
};

// Styled Components

const NavBar = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #1f1f1f;
  color: #e0e0e0;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    padding: 10px 15px;
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CenterSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e0e0e0;

  span {
    display: inline-block;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Hamburger = styled.div`
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: #e0e0e0;

  @media (max-width: 768px) {
    display: block;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 30px;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background: #1f1f1f;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
`;

const StyledLink = styled(Link)`
  color: #e0e0e0;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    color: #ff4081;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 10px 0;
  }
`;

const ProductHuntBadge = styled.a`
  display: block;

  img {
    width: 250px;
    height: 54px;
  }

  @media (max-width: 868px) {
    img {
      width: 0px;
      height: 0px;
    }
  }
`;

export default Header;
