import React from 'react';
import styled from 'styled-components';

const TermsOfService = () => {
  return (
    <Container>
      <Title>Terms and Conditions</Title>
      <Section>
        <Subtitle>1. Introduction</Subtitle>
        <Text>
          Welcome to Nothing Store, a fun and unique platform where you can purchase "nothing" as a novelty gift or for personal enjoyment. By using our website and making purchases, you agree to the following terms and conditions. Nothing Store is operated by Aphex Lab s.r.o., a company registered in the Czech Republic.
        </Text>
      </Section>
      <Section>
        <Subtitle>2. Purchase of "Nothing"</Subtitle>
        <Text>
          <b>One-Time Purchase:</b> You can buy "nothing" for a one-time fee. Upon completion of your purchase, a PDF certificate confirming your purchase of "nothing" will be sent to the email address you provide.
          <br /><br />
          <b>Subscription Purchase:</b> You may choose to subscribe to receive "nothing" on a monthly basis. Each month, a new PDF certificate confirming your ongoing subscription to "nothing" will be sent to the email address you provide.
          <br /><br />
          <b>Non-Refundable:</b> All purchases of "nothing" are final and non-refundable. Since you are purchasing a non-tangible item, there are no returns or exchanges.
        </Text>
      </Section>
      <Section>
        <Subtitle>3. Use of Email Address</Subtitle>
        <Text>
          Your email address is required for the sole purpose of sending you the PDF certificate(s) of "nothing" that you have purchased. Email addresses are securely stored by our payment processor, Stripe, and are not used for any purpose other than to deliver your purchased certificates.
        </Text>
      </Section>
      <Section>
        <Subtitle>4. Payment</Subtitle>
        <Text>
          All payments are processed securely through Stripe. We do not store or have access to your credit card details.
        </Text>
      </Section>
      <Section>
        <Subtitle>5. Cancellation</Subtitle>
        <Text>
          <b>Subscription Cancellation:</b> You may cancel your monthly subscription at any time by accessing your Stripe account or by contacting our support team at aphexlab (at) gmail.com.
          <br /><br />
          <b>One-Time Purchase Cancellation:</b> Since the purchase of "nothing" is instantaneous, cancellations of one-time purchases are not possible.
        </Text>
      </Section>
      <Section>
        <Subtitle>6. Intellectual Property</Subtitle>
        <Text>
          All content on this website, including the design, text, graphics, logos, and images, is the property of Aphex Lab s.r.o. and is protected by intellectual property laws.
        </Text>
      </Section>
      <Section>
        <Subtitle>7. Limitation of Liability</Subtitle>
        <Text>
          Aphex Lab s.r.o. shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the website or purchase of "nothing."
        </Text>
      </Section>
      <Section>
        <Subtitle>8. Governing Law</Subtitle>
        <Text>
          These terms and conditions are governed by and construed in accordance with the laws of the Czech Republic, and any disputes shall be subject to the exclusive jurisdiction of the courts in the Czech Republic.
        </Text>
      </Section>
      <Section>
        <Subtitle>9. Changes to Terms</Subtitle>
        <Text>
          Aphex Lab s.r.o. reserves the right to update these terms and conditions at any time. Any changes will be posted on this page, and your continued use of the website after such changes have been posted will constitute your acceptance of the revised terms.
        </Text>
      </Section>
      <Section>
        <Subtitle>10. Contact Us</Subtitle>
        <Text>
          If you have any questions about these Terms and Conditions, please contact us at aphexlab (at) gmail.com.
        </Text>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  padding: 40px;
  background: #F5F5F5;
  color: #333333;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 30px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.6;
`;

export default TermsOfService;
